.button {
    position: relative;

    .container {
        padding: 0 10px;
        position: relative; }

    .text {
        position: relative;
        display: inline-block;
        text-align: center;
        padding: 0 5px;
        transition: transform 0.3s ease; }

    .mini-loader {
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        transition: opacity 0.3s ease; }

    &.loading {
        .text {
            transform: translateX(-17px); }

        .mini-loader {
            opacity: 1; } } }
