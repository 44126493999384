.card-table {
    padding: 2rem 0 1rem;

    .card-table-items {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &.has-more {
            padding-right: 2rem; } }

    .key-val-item {
        width: 100%; }

    .show-more {
        cursor: pointer;
        display: block;
        position: absolute;
        height: 100%;
        height: calc(100% - 1.2rem);
        right: 0;
        width: 1.5rem;
        overflow: hidden;
        color: #cccccc;

        &:hover {
            color: #483bd4; }

        .more-menu-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } }

    @media only screen and (min-width: 600px) {
        .key-val-item {
            width: 49%;

            &:nth-child(2n+2) {
                margin-left: 2%; } } }

    @media only screen and (min-width: 900px) {
        .key-val-item {
            width: 32%;
            &:nth-child(2n+2) {
                margin-left: 0; }
            &:nth-child(3n+2) {
                margin: 0 2%; } }

        .hasRightPanel & {
            .key-val-item {
                width: 49%;
                &:nth-child(3n+2) {
                    margin: 0; }
                &:nth-child(2n+2) {
                    margin-left: 2%; } } } }

    @media only screen and (min-width: 1200px) {
        .hasRightPanel & {
            .key-val-item {
                width: 32%;
                &:nth-child(2n+2) {
                    margin-left: 0; }
                &:nth-child(3n+2) {
                    margin: 0 2%; } } } } }
