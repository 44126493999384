.deatails-view-container {
    display: grid;
    grid-template-columns: auto auto auto;

    .item-0 {
        gird-area: 1 / 1 /span 1/ span 1; }
    .item-1 {
        grid-area: 1 / 2 /span 1/ span 1; }

    .item-2 {
        grid-area: 2 / 1 /span 1/ span 1; }
    .item-3 {
        grid-area: 2 / 2 /span 1/ span 1; }
    .item-4 {
        grid-area: 2 / 3 /span 1/ span 1; }
    .item-5 {
        grid-area: 3 / 1 /span 1/ span 1; }
    .item-6 {
        grid-area: 3/ 2 /span 1/ span 1; } }
