.key-val-item {
    padding-bottom: 1.3rem;
    display: flex;
    font-size: 14px;

    .label,
    .value {
        width: 50%; }

    .value {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        background: transparentize(#483bd4, 0.95);
        padding: 0.5rem;
        text-align: center;
        font-weight: bold; }

    .label {
        padding-right: 0.5rem;
        color: #000;
        display: flex;
        align-items: center; } }
