.skill-overview {
    h2 {
        font-weight: normal; } }

.skill-id {
    padding-bottom: 1rem;

    span {
        margin-left: 1rem;
        cursor: pointer;
        background: #fafafb;
        display: inline-block;
        padding: 0.5rem;
        font-size: 1rem;
        font-weight: normal; } }


