.ant-select-selection, .ant-select-selection--single {
    border-radius: .125rem;
    background-color: #fafafb;
    height: auto;
    font-size: 1rem;
    margin-top: .25rem;
    padding: .4rem;

    &:focus, &:active, &:hover, {
        border-color: #483bd4; }
    .ant-select-selection__rendered {
        line-height: auto; }
    .ant-select-search__field {
        line-height: 1.5;
        height: auto; } }

.ant-select-open .ant-select-selection {
    border-color: #483bd4; }
