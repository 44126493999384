.company-form {
    display: grid;
    grid-template-columns: auto auto auto;

    * {
       grid-column: 1/3; }

    .city {
       grid-column: 1;
       margin-right: 1em; }

    .zip {
        grid-column: 2; } }

.file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
