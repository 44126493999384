.custom-table {
    $table-active-color: #483bd4;
    position: relative;
    padding: 1rem 0;

    .more-menu-icon {
        color: darken(#ffffff, 20);
        &:hover {
            color: $table-active-color; } }


    .ant-table {
        table {
            border: none;
            border-radius: 0; } }

    .ant-table-tbody {
        & > tr {
            & > td {
                border: none;
                font-weight: bold; } } }

    .ant-table-row {
        &:nth-child(even) {
            & > td,
            & > .ant-table-column-sort {
                background: darken(#ffffff, 1); } }

        & > td {
            transition: background 0.4s ease;
            &.ant-table-column-sort {
                background: none; }

            &:first-child {
                position: relative;
                &:after {
                    content: "";
                    background: $table-active-color;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 0px;
                    transition: width 0.3s linear; } } }
        &:hover {
            & > td {
                background: darken(#ffffff, 3);
                cursor: pointer;
                &:first-child {
                    &:after {
                        width: 4px; } } } } }

    .ant-table-fixed {
        .ant-table-row {
            & > td {
                &:first-child {
                    &:after {
                        display: none; } } } } }

    .ant-table-thead {
        & > tr {
            & > th {
                background: $table-active-color;
                border: none;
                color: #ffffff;

                &.ant-table-column-sort {
                    background: #30278f; }

                &:first-child {
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px; }
                &:last-child {
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px; } } } }

    .ant-pagination {
        margin: 0;
        padding: 1rem 0;
        list-style: none;

        li {
            display: inline-block;
            margin: 0 1px;
            outline: none; }
        i {
            display: block; }

        svg {
            padding-top: 7px; }

        a {
            display: block;
            background: #FFFFFF;
            color: invert;
            cursor: pointer;
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            overflow: hidden;
            outline: none;
            transition: background 0.4s ease, color 0.4s ease; }

        li {
            &.ant-pagination-item-active,
            &:hover {
                a {
                    background: $table-active-color;
                    color: #FFFFFF; } } } } }
