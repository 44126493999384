/* style for the up and down arrows indicating close and open sub menu */
.submenu-indicator {
  margin-left: auto;
  color: white;
  cursor: pointer; }

#side-nav-list {
    margin-top: 50px; }

.profile-settings {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2000; }


.hide-settings {
  height: 0;
  visibility: hidden; }

