.message {
    margin-bottom: .5rem;
    position: relative;

    h4 {
        font-size: 15px; }

    &.anim {
        overflow: hidden;

        .message-content {
            animation: msgShow .6s ease; } } }

.message-content {
    & > .pa2 {
        padding-left: 3rem;
        padding-right: 1rem; } }

.message-date {
    position: absolute;
    right: 1rem;
    top: .75rem;
    font-size: .8rem;
    opacity: .6; }

@keyframes msgShow {
    0% {
        opacity: 0;
        transform: translateY(50%); }
    70% {
        transform: translateY(0); }
    100% {
        opacity: 1; } }
