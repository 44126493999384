.message-logo {
    width: 30px;
    height: 30px;
    position: absolute;
    top: .5rem;
    left: .5rem;
    border-radius: 100%;

    &.user-logo {
        background: lighten(#0e0b2a, 38); }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: white;
        font-size: 14px; } }
