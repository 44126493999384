.diagram {
  list-style: none;
  counter-reset: diagram 0;

  li {
    counter-increment: diagram;
    position: relative;
    padding: .375em 0; }

  li:before {
    content: counter(diagram);
    position: absolute;
    padding: 0 .5em;
    background-color: #f2f2fa;
    border-radius: 50%;
    transform-origin: 100% 100%;
    transform: translateX(-125%); } }



.section-icon {
  transform: translateY(4px);
  width: 24px;
  height: 24px;
  margin: .5em; }



img.contact-docs-icon:hover {
  transform: scale(1.3);
  transition: transform 0.1s ease-in; }
