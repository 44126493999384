@font-face {
  font-family: 'anticon';
  src: url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot');
  src: url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff') format("woff"), url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf') format("truetype"), url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont') format("svg"); }


.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ant-select.ant-select-enabled {
  width: 100%; }

.ant-select-selection.ant-select-selection--multiple {
  margin-top: .25rem;
  padding: .5rem 2rem .5rem .5rem;
  font-size: 1rem;
  font-family: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .125rem;
  background-color: #fafafb;
  -webkit-appearance: none; }

.ant-select-open {
  &.ant-select-selection, &:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ant-select-selection {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  &:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; } }

.ant-select-open.ant-select-selection {
  border-color: #4D90FE !important; }

.ant-select:hover .ant-select-selection {
  border-color: #483bd4; }

li.ant-select-selection__choice {
  color: black !important;
  background-color: rgb(253, 253, 253) !important; }

.ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

ul.ant-select-dropdown-menu {
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 0;

  > li {
    font-size: 1rem;
    border-radius: 0;
    line-height: 1.5;
    color: black;
    background-color: #fff;
    &:hover {
      background-color: #527bd4c7; } } }

.ant-select-dropdown-menu-item:first-child,
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0; }
