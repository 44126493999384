.ant-calendar-picker{
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .125rem;
    background-color: #fafafb;
    -webkit-appearance: none;
}

.ant-calendar-picker:hover {
    border-color: #483bd4;
}

.ant-calendar-picker-input.ant-input{
    border:  none;
    background-color: #fafafb;
    height: 40px;
    line-height: 1.5;
    text-align: center;
}