.ant-notification {
    z-index: 2001 !important;
}

button {
    outline: none !important;
}

/* CSS Loader */

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #0e0b2a;
    margin: 5px auto;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  .mini-loader{
    display: inline-block;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #0e0b2a;
    margin: 0 2px -3px 4px;
    width: 18px;
    height: 18px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* general link style resetting  for react-router Link Component */
 a:link,
 a:hover,
 a:active,
 a:visited{
        text-decoration: none;
        color: white;
}

/* overriding the general reset fr link class */
.link:link,
.link:hover,
.link:active,
.link:visited{
    color:#483bd4;
    line-height: inherit;
    font-size: inherit;
    cursor: pointer
}

.link:focus {
    outline: none;
}

.link, .link-menu, .link-button{
    cursor: pointer;
}

.link-button:link,
.link-button:hover,
.link-button:active,
.link-button:visited {
    color: #483bd4;
    text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

#main-layout {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    padding: 1rem;
}

@media screen and (min-width: 768px) {
    #main-layout {
        padding: 1rem 64px;
    }
}

@media screen and (min-width: 1150px) {
    #main-layout {
        padding: 1rem 104px;
    }
}

#logo-bg-wrap {
    position: absolute;
    top: 0;
    right: 0;
    height: 300px;
    width: 632px;
}

#logo-bg {
    position: fixed;
    z-index: 1;
    height: 682px;
    width: 632px;
    max-width: 632px;
    opacity: 0.05;
}

#hala {
    margin-left: 10px;
}

#navbar {
    margin-bottom: 1rem;
}

.ant-layout-content {
    position: relative;
    z-index: 2;
}

.table-loader {
    width: 100%;
    padding: 100px 0;
}

.table-loader svg,
.table-loader img {
    margin: 0 auto;
    display: block;
    opacity: 0.2
}
