.ibm-link {
    max-width: 400px;
    margin: 6rem auto;
    text-align: center;

    .border {
        border-top: 1px solid rgba(0,0,0,0.2);
        width: 200px;
        margin-left: auto;
        margin-right: auto; }
    h4 {
        width: 100%;
        display: inline-block; } }






