.modal-container {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: table;

    .middle {
        display: table-cell;
        vertical-align: middle;

        div {
            margin: 0 auto;
            max-width: 600px; } } }


