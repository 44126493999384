.analytics-logs {
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .ant-select-selection--multiple {
        padding: 0.2rem;
        min-height: 20px;

        .ant-select-selection__rendered > ul > li {
            margin-top: 0.1rem;
            font-size: 0.9rem; }

        .ant-select-selection__choice {
            background-color: rgba(0,0,0,0.05) !important;
            color: rgba(0,0,0,0.7) !important; } }

    .page-header {
        .ant-select {
            width: 100%; } } }
