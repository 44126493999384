input {
    outline: none;
    transition: border-color 0.4s ease;
    &[type="submit"] {
        transition: border-color 0.4s ease,background-color 0.4s ease; } }

button {
    outline: none;
    transition: background-color 0.4s ease, border-color 0.4s ease; }

.Toast__toast___2UNlJ {
    display: none;

 }    // default color is white and after company setting being updated it creates conflict with our styling
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px #fafafb inset; }

.i-arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

    &.right {
        transform: rotate(-45deg); }

    &.left {
        transform: rotate(135deg); }

    &.up {
        transform: rotate(-135deg); }

    &.down {
        transform: rotate(45deg); } }

.page-wrap {
    h2 {
        font-weight: normal; } }
