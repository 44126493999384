.page-header {
    padding: 1.5rem 3rem;
    margin: 0;
    position: relative;

    h2 {
        margin: 0;
        padding: 0;
        font-weight: normal;
        position: relative; }

    .go-back {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0.1rem;
        width: 5px;
        height: 5px;
        display: block;
        border-color: rgba(0,0,0,0.2);
        transition: border-color 0.4s ease;

        &:hover {
            border-color: rgba(0,0,0,1); }

        &.i-arrow.left {
            transform: translateY(-50%) rotate(135deg);
            transform-origin: center; }

        & + span {
            padding-left: 2rem; } } }
